import NextLink from 'next/link';
import { Box, Button, Container, Typography, useTheme, useMediaQuery } from '@mui/material';
import Page from 'src/components/Page';

const classes = {
  root: {
    backgroundColor: 'background.dark',
    minHeight: '100%',
    display: 'flex',
    alignItems: 'center',
    padding: 3,
    paddingTop: '80px',
    paddingBottom: '80px',
  },
  image: {
    maxWidth: '100%',
    width: '560px',
    maxHeight: '300px',
    height: 'auto',
  },
};

function Error404View() {
  const theme = useTheme();
  const mobileDevice = useMediaQuery(theme.breakpoints.down('md'));
  const isAuthenticated = false;

  return (
    <Page sx={classes.root} title="404: Not found">
      <Container maxWidth="lg">
        <Typography
          align="center"
          component="h1"
          variant={mobileDevice ? 'h4' : 'h1'}
          color="textPrimary"
        >
          The page you are looking for isn’t here
        </Typography>
        <Typography align="center" variant="subtitle2" color="textSecondary">
          You either tried some shady route or you came here by mistake. Whichever it is, try using
          the navigation.
        </Typography>
        <Box mt={6} display="flex" justifyContent="center">
          <Box
            component="img"
            alt="Under development"
            sx={classes.image}
            src="/static/images/undraw_page_not_found_su7k.svg"
          />
        </Box>
        <Box mt={6} display="flex" justifyContent="center">
          {isAuthenticated ? (
            <NextLink href="/" passHref>
              <Button color="secondary" component="a" variant="outlined">
                Back to home
              </Button>
            </NextLink>
          ) : (
            <NextLink href="/" passHref>
              <Button color="secondary" component="a" variant="outlined">
                Back to home
              </Button>
            </NextLink>
          )}
        </Box>
      </Container>
    </Page>
  );
}

export default Error404View;
